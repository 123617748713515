import { TransactionsExport } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    ListItem,
    ListItemText,
    useTheme,
} from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useTransactionListExportDialog } from './TransactionListExportDialog';
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded';

interface FormColumn {
    key: string;
    selected: boolean;
}
type FormValues = {
    columns: FormColumn[];
};

interface SecondStepProps {
    onSuccess: () => void;
}
export function SecondStep({ onSuccess }: SecondStepProps) {
    const open = useTransactionListExportDialog(store => store.open);
    const hide = useTransactionListExportDialog(store => store.hide);
    const filter = useTransactionListExportDialog(store => store.filter);
    const setFormat = useTransactionListExportDialog(store => store.setFormat);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            columns: Object.values(TransactionsExport.COLUMNS).map(item => ({
                key: item.key,
                selected: item.selected ?? true,
            })),
        },

        // validationSchema: TransactionsExport.validationSchema,

        onSubmit: async values => {
            if (!filter) {
                setDisplayError(true);
                setError('Неизвестная ошибка (filter)');
                return;
            }

            console.log(values);

            const columns = values.columns
                .filter(item => item.selected)
                .map(item => item.key) as unknown as keyof TransactionsExport.RowType[];

            console.log(columns);

            setFormat({ columns: columns });
            onSuccess();
        },
    });

    const selectAll = () => {
        formik.setValues(values => ({
            ...values,
            columns: values.columns.map(item => ({ ...item, selected: true })),
        }));
    };

    return (
        <>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <ListItem
                        disableGutters
                        secondaryAction={
                            <Button startIcon={<ChecklistRoundedIcon />} onClick={selectAll}>
                                Выбрать все
                            </Button>
                        }
                    >
                        <ListItemText
                            primary="Выберите поля, необходимые в документе"
                            primaryTypographyProps={{ fontWeight: 500 }}
                        />
                    </ListItem>
                    <FormGroup>
                        {Object.values(formik.values.columns).map((item, index) => {
                            const selectedProp = `columns[${index}].selected`;
                            return (
                                <FormControlLabel
                                    key={item.key}
                                    name={selectedProp}
                                    control={<Checkbox checked={item.selected} />}
                                    label={
                                        TransactionsExport.COLUMNS[item.key as keyof TransactionsExport.RowType]?.header
                                    }
                                    onChange={formik.handleChange}
                                />
                            );
                        })}
                    </FormGroup>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Экспорт</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}
